//Type

/*
font-family: 'Noto Sans KR', sans-serif;
font-family: 'Rubik', sans-serif;
*/

body {
    font-family: 'Noto Sans KR', sans-serif;
}

h1, h2 {
    font-family: 'Rubik', sans-serif;
}

a {
    text-decoration: none;
}
