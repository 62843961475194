.page-works {
  width: em(900);
  max-width: calc(100% - em(20));
  margin: 0 auto;
  padding: em(60) em(10);

  .page-works-wrapper {
    padding-bottom: em(20);
    .ul {
      list-style: none;
      padding-left: 0;
      display: flex;

      .first-li {
        margin-right: em(6);
        color: $text-color;
      }
      .second-li {
        color: $main-color;
        svg {
          margin-right: em(6);
          color: $text-color;
        }
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: em(20);

    .btn {
      background-color: $background-color;
      color: $text-color;
      align-items: center;
      outline: none;
      padding: em(10);
      width: em(90);
      border: em(1) solid $shadow-color-three;
      border-radius: em(10);
      box-shadow: $shadow-color-two 0px 2px 4px;
      cursor: pointer;
      touch-action: manipulation;
      transition: all 250ms;
      position: relative;

      &:hover,
      &:focus {
        border-color: $shadow-color-four;
        box-shadow: $shadow-color-three 0 em(4) em(12);
        color: $shadow-color-five;
      }

      &:hover {
        transform: translateY(em(-1));
      }

      &:not(:last-child) {
        margin-right: em(10);
      }

      &.active:focus {
        background-color: $div-box;
        border-color: $shadow-color-four;
        box-shadow: $shadow-color-three 0 em(2) em(4);
        color: $shadow-color-five;
        transform: translateY(0);
      }
    }
  }

  .card {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: em(15);
    transition: 0.4s ease-in-out;

    .card-wrapper {
      height: 100%;

      .card-container {
        padding: em(3);
        background-color: $div-box;
        box-shadow: $shadow-color 0px 5px 15px;
        border-radius: em(8);
        height: 100%;
        .card-image {
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding: em(15);
          height: 100%;
          .top-part {
            .subtop {
              display: flex;
              justify-content: space-between;

              .code-icon {
                width: em(50);
                height: em(50);
                border-style: solid;
                display: grid;
                place-content: center;

                svg {
                  width: em(40);
                  height: em(40);
                  padding: em(2);
                  fill: $text-color;
                }
              }
              .code-icon-two {
                width: em(50);
                height: em(50);
                border-style: solid;
                display: grid;
                place-content: center;

                svg {
                  width: em(40);
                  height: em(40);
                  padding: em(2);
                }
              }
            }
          }
          .middle-part {
            word-wrap: break-word;
            margin-top: em(12);
            h2 {
              color: $text-color;
              &::before {
                content: "";
                display: block;
                position: absolute;
                z-index: 0;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
              }

              &:hover {
                color: $main-color;
              }
            }
            p {
              font-size: em(14);
            }
          }
          .bottom-part {
            display: flex;
            flex-direction: column;
            align-items: start;
            margin-top: em(12);
            position: relative;
            p {
              text-align: start;
              font-size: em(11);
            }
            .lists {
              display: flex;
              // padding: em(10) 0;
              li {
                list-style: none;
                font-size: em(11);
                &:not(:last-child) {
                  margin-right: em(10);
                }
              }
            }
            .bottom-btn {
              // display: grid;
              // place-content: center;
              margin-top: em(5);
              a {
                color: $text-color;
                font-size: em(15);
                border: em(2) solid $text-color;
                line-height: em(40);
                width: 50%;
                padding: em(6.1) em(25);
                z-index: 2;

                &:not(:last-child) {
                  margin-right: em(10);
                }

                &:hover {
                  color: $background-color;
                  background-color: $text-color;
                  transition: all 0.4s ease;
                }
              }
            }
          }
        }
      }
    }
  }
}
