.nav-main {
    width: em(450);
    height: 100vh;
    background-color: $black;
    position: fixed;
    top: 0;
    right: em(-450);
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;
    clip-path: polygon(43% 0, 100% 0%, 100% 100%, 0 100%, 23% 48%);

    &.active {
        right: 0;
    }

    .main {
        margin: 0;
        padding: 0;
        list-style: none;
        color: $white;
        font-size: 40px;

        li {
            margin: em(10) 0;
            padding-left: em(20);
            transition: all 0.2s ease-in-out;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 70%;
                transform: scaleX(0);
                height: em(2);
                bottom: 0;
                left: em(20);
                transform-origin: bottom right;
                transition: transform 0.25s ease-out;
                background-color: $main-color;            
            }

            &:hover {
                &::after {
                    transform: scaleX(1);
                    transform-origin: bottom left;
                }
            }

            &.active {
                &::before {
                    content: "";
                    position: absolute;
                    width: 70%;
                    height: em(2);
                    bottom: 0;
                    left: em(20);
                    background-color: $main-color;
                }
            }

            a {
                color: $white;
                text-decoration: none;

                &:hover {
                    color: #F0F0F1;
                }
            }
        }
    }
    .social {
        margin: em(10) 0;
        display: flex;
        flex-direction: row;
        list-style: none;

        li {
            margin: 0 em(5);

            a {
                color: $white;

                &:hover {
                    color: $main-color;
                }
                
                svg {
                    font-size: em(40);
                }
            }
        }
    }
    .darkmode-toggle {
        list-style: none;
        #darkmode {
            width: 40px;
            height: 40px;
            filter: drop-shadow(3px 5px 2px rgba(139, 167, 12, 0.769));
            transition: all 0.4s ease-in-out;

            &:hover,
            &:focus {
                cursor: pointer;
                path {
                    fill: #e4e411;
                }
            }

            path {
                fill: $subColor;
            }
        }
    }
}