//Resets
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  background-color: $background-color;
  color: $text-color;
  scrollbar-width: thin;
}

body::-webkit-scrollbar {
  width: em(10);
}

body::-webkit-scrollbar-track {
  background-color: $white;
}

body::-webkit-scrollbar-thumb {
  background-color: $main-color;
  border-radius: em(6);
}
